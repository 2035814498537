/* ------ Establish Namespace ------- */

var vawa = {};

/**
 * window width
 * @type {int}
 */
vawa.viewport = window.innerWidth;
/**
 * window height
 * @type {int}
 */
vawa.viewHeight = window.innerHeight;

  


/**
 * Function to delay function call
 * @param  {Function} fn    [the function to debounce]
 * @param  {int}   delay [The delay amount]
 * @return {function}         [description]
 */
vawa.debounce = (fn, delay) => {
  var timer = null;
  return function() {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  }
}



/**
 * function to get index of an array
 * @param  {array} array [The array]
 * @param  {int} el    [the index to look for]
 * @return {int}       
 */
  vawa.getIndexInArray = (array, element) => {
    return Array.prototype.indexOf.call(array, element);
  };



/**
 * Function to see if class exists in an element
 * @param  {DOM Object}  element   [the element to test]
 * @param  {String}  className [The classname to query for]
 * @return {Boolean}           [description]
 */
vawa.hasClass = (element, className) => {

  if (element.classList) {
    return element.classList.contains(className);
  }
  else {
    return element.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(element.className);
  }

        
}


/**
 * Function to toggle class
 * @param {string} className    The name of the class to toggle
 * @param {[type]} element The dom element
 */
vawa.toggleClass = (element, className) => {
  let classesString;
  classesString = element.className || "";
  if (classesString.indexOf(className) === -1) {
    element.className += " " + className;
  }
  else {
    element.className = element.className.replace(className, '');
  }
}

/**
 * Function to add class
 * @param {DOM element} element   [the element to add the class to]
 * @param {string} className [the classname added]
 */
vawa.addClass = (element, className) => {
  var classList = className.split(' ');
  if (element.classList) element.classList.add(classList[0]);
  else if (!vawa.hasClass(element, classList[0])) element.className += " " + classList[0];
  if (classList.length > 1) vawa.addClass(element, classList.slice(1).join(' '));
};


/**
 * Function to remove class
 * @param  {DOM element} element        [the element to remove the class from]
 * @param  {string} className [the class to be removed]
 * @return {null}           [description]
 */
vawa.removeClass = (element, className) => {
  var classList = className.split(' ');
  if (element.classList) {
    element.classList.remove(classList[0]);  
  }
  else if(vawa.hasClass(element, classList[0])) {
  var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
  element.className=element.className.replace(reg, ' ');
  }
  if (classList.length > 1) {
    vawa.removeClass(element, classList.slice(1).join(' '));
  }
};


/**
 * Function to check if element is in viewport
 * @param  {DOM element} element [The element to test]
 * @return {boolean}         [description]
 */
vawa.elementInViewport = (element) => {
  var top = element.offsetTop;
  var left = element.offsetLeft;
  var width = element.offsetWidth;
  var height = element.offsetHeight;

  while(element.offsetParent) {
      element = element.offsetParent;
      top += element.offsetTop;
      left += element.offsetLeft;
  }

  return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
  );
}


/**
 * Function to get the closest parent node with matching selector
 * @param  {DOM element} element  [The element's parentnode to search for]
 * @param  {string} selector [The selector to search for]
 * @return {null}          [description]
 */
vawa.getClosest = (element, selector) => {

  // Element.matches() polyfill
  if (!Element.prototype.matches) {
      Element.prototype.matches =
          Element.prototype.matchesSelector ||
          Element.prototype.mozMatchesSelector ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.oMatchesSelector ||
          Element.prototype.webkitMatchesSelector ||
          function(s) {
              var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                  i = matches.length;
              while (--i >= 0 && matches.item(i) !== this) {}
              return i > -1;
          };
  }

  // Get the closest matching element
  for ( ; element && element !== document; element = element.parentNode ) {
    if ( element.matches( selector ) ) return element;
  }
  return null;

};




/**
 * Promised based AJAX wrapper function
 * @param  {string} url    [location to send request]
 * @param  {string} method [request type]
 * @return {promise}        jalene
 */
vawa.ajaxRequest = (url, method) => {
    // Create the XHR request
    let request = new XMLHttpRequest();

    // Return a Promise
    return new Promise(function(resolve, reject){

        request.onreadystatechange = () => {
            // Only run if the request is complete
            if (request.readyState !== 4) {
                return;
            }
            // Process the response
            if (request.status >= 200 && request.status < 300) {
                // Successful
                resolve(request);
            } else {
                // Failed
                reject({
                    status: request.status,
                    statusText: request.statusText
                });
            }           
        }

        // Setup our HTTP request       
        request.open(method || 'GET', url, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        // Send the request
        request.send();     

    });
};



/**
 * Function to smooth scroll
 * @param  {DOM object} destination  [The item to scroll to]
 * @param  {int} duration       [The animation duration]
 * @param  {string} easing [The easing effect for the animation]
 * @return null          
 */
vawa.scrollIt = (destination, duration, easing) => {

  var easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };


  let start = window.pageYOffset;
  let startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  let documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
  let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
  let destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
  let destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    return;
  }

  function scroll() {
    let now = 'now' in window.performance ? performance.now() : new Date().getTime();
    let time = Math.min(1, ((now - startTime) / duration));
    let timeFunction = easings[easing](time);
    window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    if (window.pageYOffset === destinationOffsetToScroll) {
      return;
    }
    requestAnimationFrame(scroll);
  }

  scroll();
}



vawa.defaultModalClose = () => {
  document.addEventListener('click', function(e){
    if(e.target.tagName == 'A' && vawa.hasClass(e.target, 'close')) {
      const defModal = vawa.getClosest(e.target, '.alert');
      defModal.parentNode.remove(defModal);
    }
    
  })

}


vawa.modalOpen = (target, title) => {
    const defaultModal = document.querySelector('.modal-default'); 
    const display = window.getComputedStyle(defaultModal, null).getPropertyValue('display');    

    // Close Modal
    if(display == 'block') {     

        // Animate 
        Velocity(defaultModal, 'transition.bounceUpOut', 800, [0.31,0.3,0.54,1.05]);
        // Remove overlay & clear modal
        let modalOverlay = defaultModal.nextElementSibling;
        let modalHeading = defaultModal.querySelector('h4');
        var modalText = defaultModal.querySelector('.modal-default-body');        
        modalOverlay.className = modalOverlay.className.replace(/(?:^|\s)opacity(?!\S)/g , '' );
        window.setTimeout(function(){
            modalOverlay.parentNode.removeChild(modalOverlay);
            modalHeading.parentNode.removeChild(modalHeading);
            modalText.parentNode.removeChild(modalText);            
        }, 800);   

        // Set attributes
        target.setAttribute('aria-expanded', false);
        defaultModal.setAttribute('aria-expanded', false);  
        defaultModal.setAttribute('aria-hidden', true);    
        defaultModal.setAttribute('tabindex', -1);          
    }
    // Open Modal
    else {
        // Insert content into modal
        let modalHeadingText = title.innerHTML;
        let modalText = target.nextElementSibling.cloneNode(true);   
        let modalHeading = document.createElement('h4');        
        modalHeading.innerHTML = modalHeadingText;
        defaultModal.insertBefore(modalHeading, defaultModal.firstChild.nextSibling);
        defaultModal.childNodes[1].parentNode.insertBefore(modalText, defaultModal.childNodes[1].nextSibling);                

        // Animate modal
        Velocity(defaultModal, 'transition.bounceDownIn', 800, [0.390, 0.015, 0.770, 1]);
        // Create overlay
        let modalOverlay = document.createElement('div');
        modalOverlay.className +=  'modal-overlay';
        defaultModal.parentNode.insertBefore(modalOverlay, defaultModal.nextSibling);
        window.setTimeout(function(){
            modalOverlay.className += ' opacity';
        }, 100);  
        // set attributes
        target.setAttribute('aria-expanded', true); 
        defaultModal.setAttribute('aria-expanded', true); 
        defaultModal.setAttribute('aria-hidden', false);
        defaultModal.setAttribute('tabindex', 0);   
    }
}




vawa.toolkitOpen = () => {


  
  if(document.querySelector('.toolkit-subject') !== null) {
    // Trigger Open Toolkit Modal
      const toolkitButtons = document.querySelectorAll('.toolkit-subject');
      [].forEach.call(toolkitButtons, function(button){
          button.addEventListener('click', function(e){  
              let toolkitTitle = button.querySelector('h3');
              vawa.modalOpen(button, toolkitTitle);
          });
      });

    // Trigger Close Toolkit Modal      
    const toolkitClose = document.querySelector('#modal-toolkit .modal-close');
    toolkitClose.addEventListener('click', function(e){
        let modalHeading = new RegExp(e.target.nextSibling.innerHTML);
        let toolkitHeadings = document.querySelectorAll('.toolkit-subject-inner > h3');
        for(let i = 0; i < toolkitHeadings.length; i++) {
            if(modalHeading.test(toolkitHeadings[i].innerHTML)) {
              console.log(toolkitHeadings[i].parentNode.nextElementSibling);
               vawa.modalOpen(toolkitHeadings[i].parentNode.parentNode);
            }
        }
    });




  }
}


  

/**
 * Function to trigger smoolth scroll for main nav links
 * @return {[type]} [description]
 */
vawa.smoothNav = function() {

  const anchorLinks = document.querySelectorAll('a.scroll-link');
  [].forEach.call(anchorLinks, function(link){
      link.addEventListener('click', function(e){
          let anchor = document.querySelector(link.getAttribute('href').replace('/', ''));
          vawa.scrollIt(anchor, 1000, 'easeOutQuart');
      });
  });    
}


/**
 * Function to initialize youtube videos
 * @return {null} [description]
 */
vawa.youtubeLoad = () => {
    document.addEventListener('click', function(e){
        if(vawa.hasClass(e.target, 'play') || vawa.hasClass(e.target, 'youtube-thumb')) {
            vawa.addVideo(e.target);
        }
    }); 

    // Trigger on keyboard enter
    document.addEventListener('keyup', function(e){
        if(vawa.hasClass(e.target, 'play')) {
            if(e.keyCode === 13) {
                vawa.addVideo(e.target);  
            }
            
        }
    });

} 

/**
 * Function to swap youtube image with video
 * @param {DOM element} element [the thumbnail image that is to be replaced]
 */
vawa.addVideo = (element) => {
    let iframe = document.createElement("iframe");
    let embed = "https://www.youtube.com/embed/ID?enablejsapi=1&autoplay=1&rel=0";
    let parent = element.parentNode;
    iframe.setAttribute("src", embed.replace("ID", parent.dataset.id));
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    parent.parentNode.replaceChild(iframe, parent);

}



  /**
   * Function to display button to scroll up to portal menu
   * @return {null} [description]
   */
  vawa.detectScroll = () => {
    let scrollUpBtn = document.querySelector('.btn-scroll-up');
    if(scrollUpBtn) {
      let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop    
      if(scrollTop > 1600) {
        scrollUpBtn.style.display = 'inline-block';
      }
      else {
        scrollUpBtn.style.display = 'none';
      }  
    }  
  }

  /**
   * Function to add click event to scroll up button
   * @return {null} [description]
   */
  vawa.scrollUp = () => {    
    let scrollUpBtn = document.querySelector('.btn-scroll-up');
    if(scrollUpBtn) {
      let scrollAnchor = document.querySelector('.hero');
      scrollUpBtn.addEventListener('click', function(e){
        vawa.scrollIt(scrollAnchor, 1000, 'easeOutQuart');
      });
    }
  }




// Function to be called when DOM is ready
vawa.callback = function() {

  vawa.toolkitOpen();
  vawa.smoothNav();
  vawa.defaultModalClose();
  vawa.youtubeLoad();
  vawa.scrollUp();
  window.addEventListener('scroll', vawa.debounce(vawa.detectScroll, 100));
}

// Check if DOM is ready
if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
  vawa.callback();
} else {
  document.addEventListener('DOMContentLoaded', vawa.callback);
}